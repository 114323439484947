
var calendarEndpoint = '/api/calendar';

function storePassword(password) {
  localStorage.setItem('password', password);
}

function renderDate(json, t, isEnd, allDay) {
  const userTimezone = json.timezone;
  const userLocale = json.locale;
  console.log(t)
  const date = new Date(t);

  const allDayEnd = isEnd && allDay;
  if (allDayEnd) {
    // set back by one day
    date.setTime(date.getTime() - 24 * 60 * 60 * 1000);
  }

  // Get the date in a format like: Vr 29 Jan 18:30
  const day = date.toLocaleString(userLocale, { timeZone: userTimezone, weekday: 'short' });
  const dayNumber = date.toLocaleString(userLocale, { timeZone: userTimezone, day: 'numeric' });
  const month = date.toLocaleString(userLocale, { timeZone: userTimezone, month: 'short' });
  const time = allDay ? "" :
    date.toLocaleString(userLocale, { timeZone: userTimezone, hour: 'numeric', minute: 'numeric' });
  return `${day} ${dayNumber} ${month} ${time}`;
}

function render(json, content) {
  // <p>Timezone: ${json.timezone}</p>

  let html = `
    <h1>${json.name}</h1>
    <p>${json.description}</p>
    <ul class="items">
  `;
  json.events.forEach(event => {
    html += `
      <li>
        <p>${event.title}</p>
        <p>${renderDate(json, event.startUTC, false, event.allDay)} &mdash; ${renderDate(json, event.endUTC, true, event.allDay)}</p>
        ` + (event.location ? `<p>Plaats: ${event.location}</p>` : '') + `
        ` + (event.description ? `<p>Beschrijving: ${event.description}</p>` : '') + `
      </li>
    `;
  });
  html += `</ul>`;

  content.innerHTML = html;
}

// register form event handlers when page is loaded
document.addEventListener('DOMContentLoaded', () => {
  // get the form elements defined in your form HTML above

  // const form = document.getElementById('login-form');
  const password = document.getElementById('password');
  const button = document.getElementById('button');
  const status = document.getElementById('status');
  const content = document.getElementById('content');

  function go(event) {
    event.preventDefault();
    status.innerHTML = "Loading...";
    fetch(calendarEndpoint, {
      method: 'POST',
      body: password.value,
    }).then(async (res) => {
      if (res.status === 200) {
        storePassword(password.value);
        const json = await res.json();
        console.log(json);
        render(json, content);
        window.calimiraContent = res;
      }
      else if (res.status === 401) {
        status.innerHTML = "Incorrect password";
      }
      else if (res.status === 400) {
        status.innerHTML = "Please provide the password";
      }
      else {
        status.innerHTML = "Error: " + res.status;
      }
    });
  }
  button.addEventListener('click', go);
  password.addEventListener('keyup', (event) => {
    if (event.key === 'Enter') {
      go(event);
    }
  });

  // This is not the most secure thing ever, but who needs security anyway?
  if (localStorage.getItem('password')) {
    password.value = localStorage.getItem('password');
    button.click();
  }

})
